import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import {useCalendarData} from './core/CalendarDataProvider'
import ImageSwiper from './ImageSwiper'
import {Branch} from './core/_models'

function getBusinessDaysName(days: string) {
  const daysArray = []
  if (days.includes('1') === true) daysArray.push('Даваа')
  if (days.includes('2') === true) daysArray.push('Мягмар')
  if (days.includes('3') === true) daysArray.push('Лхагва')
  if (days.includes('4') === true) daysArray.push('Пүрэв')
  if (days.includes('5') === true) daysArray.push('Баасан')
  if (days.includes('6') === true) daysArray.push('Бямба')
  if (days.includes('0') === true) daysArray.push('Ням')
  return daysArray
}

export const WelcomePageTheme2: FC = () => {
  const {onlineBookingSettings, branches} = useCalendarData()
  const {settings} = useAuth()
  const currentYear = new Date().getFullYear()
  const [businessDays, setBusinessDays] = useState<any>([])
  const formattedAmount =
    Intl.NumberFormat('en-US').format(
      parseInt(
        onlineBookingSettings?.validate_amount ? onlineBookingSettings?.validate_amount : '0'
      )
    ) + '₮'

  useEffect(() => {
    if (settings?.has_branch) {
      const branchBusinessDays: any[] = []
      branches.map((branch: Branch, index: number) => {
        if (branch.business_days) {
          const result = getBusinessDaysName(branch.business_days)
          branchBusinessDays.push(result)
        }
      })
      setBusinessDays(branchBusinessDays)
    } else {
      if (settings) {
        const result = getBusinessDaysName(settings.business_days)
        setBusinessDays(result)
      }
    }
  }, [branches])

  const JumpToWeb = () => {
    window.open('http://xolbooc.com/')
  }

  return (
    <div data-clr={onlineBookingSettings.theme_color} className='welcome'>
      {/* <div className='welcome-header' onClick={JumpToWeb}>
        <div
          style={{maxWidth: 1232, paddingInline: 16, marginInline: 'auto'}}
          className='d-flex justify-content-between'
        >
          <h3 style={{color: '#555'}}>ХОЛБООС</h3>
          <span></span>
        </div>
      </div> */}
      <div
        style={{
          maxWidth: 1232,
          paddingInline: 16,
          marginInline: 'auto',
          minHeight: 'calc(100vh - 60px)',
        }}
      >
        <div className='d-flex flex-column flex-md-row justify-content-between align-items-center gap-4 py-6'>
          <div className='d-flex align-items-center gap-4 w-100'>
            <div className='welcome-company-logo' style={{width: 80, height: 80}}>
              <img
                src={settings?.logo_url}
                alt='logo'
                className='logo_img'
                style={{width: '100%', height: '100%'}}
              />
            </div>
            <span className='welcome-company-name'>{settings?.company_name}</span>
          </div>
          <Link to='/booking/service' className='welcome-booking-btn btn'>
            Цаг авах
          </Link>
        </div>
        <div className='d-flex flex-column flex-md-row flex-lg-row mb-6' style={{height: '100%'}}>
          <div className='welcome-swiper-container'>
            <ImageSwiper />
          </div>
          <div className='welcom-sideMenu'>
            {settings?.has_branch ? (
              <div
                className='d-grid gap-4 welcome-about-us-content my-4'
                style={{maxHeight: 600, overflowY: 'auto'}}
              >
                {branches.map((branch: Branch, index: number) => {
                  return (
                    <div
                      key={index}
                      className='d-flex flex-column gap-3 welcome-branch-box'
                      style={{
                        borderRadius: '6px',
                        padding: '14px',
                      }}
                    >
                      <div
                        className='fw-bolder text-gray-800 mb-1'
                        style={{textAlign: 'center', fontSize: '16px', lineHeight: '14px'}}
                      >
                        {branch?.name}
                      </div>
                      <div className='d-flex align-items-center gap-3'>
                        <i className='fa fa-solid fa-location-dot welcome-branch-box-icon fs-3'></i>

                        <div className='d-flex gap-2 flex-wrap ps-4'>
                          <span style={{lineHeight: '14px'}}>{branch?.address}</span>
                        </div>
                      </div>
                      {branch?.phone && (
                        <div className='d-flex align-items-center gap-3'>
                          <i className='fa fa-solid fa-phone welcome-branch-box-icon fs-4'></i>
                          <div className='d-flex gap-2 flex-wrap ps-4'>
                            <span style={{lineHeight: '14px'}}>{branch.phone}</span>
                          </div>
                        </div>
                      )}

                      <div className='d-flex align-items-center gap-3'>
                        <i
                          className='fa fa-solid fa-calendar-days welcome-branch-box-icon fs-3'
                          aria-hidden='true'
                        ></i>
                        <div className='d-flex gap-1 flex-wrap ps-4'>
                          {businessDays[index] &&
                            businessDays[index].map((day: string, ind: number) => {
                              return (
                                <span key={ind} style={{lineHeight: '14px'}}>
                                  {day},
                                </span>
                              )
                            })}
                        </div>
                      </div>

                      <div className='d-flex align-items-center gap-3'>
                        <i className='fa fa-solid fa-clock welcome-branch-box-icon fs-3'></i>
                        <div className='d-flex gap-2 flex-wrap ps-4'>
                          <span style={{lineHeight: '14px'}}>
                            {branch?.start_time} - {branch?.end_time}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className='d-grid gap-4 welcome-about-us-content mb-4'>
                <div
                  className='d-flex flex-column gap-3 welcome-branch-box'
                  style={{
                    borderRadius: '6px',
                    padding: '14px',
                  }}
                >
                  <div className='d-flex align-items-center gap-3'>
                    <i className='fa fa-solid fa-location-dot welcome-branch-box-icon fs-3'></i>
                    <div className='d-flex gap-2 flex-wrap ps-4'>
                      <span style={{lineHeight: '14px'}}>{settings?.address}</span>
                    </div>
                  </div>
                  {settings?.phone && (
                    <div className='d-flex align-items-center gap-3'>
                      <i className='fa fa-solid fa-phone welcome-branch-box-icon fs-4'></i>
                      <div className='d-flex gap-2 flex-wrap ps-4'>
                        <span style={{lineHeight: '14px'}}>{settings.phone}</span>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center gap-3'>
                    <i
                      className='fa fa-solid fa-calendar-days welcome-branch-box-icon fs-3'
                      aria-hidden='true'
                    ></i>
                    <div className='d-flex gap-1 flex-wrap ps-4'>
                      {businessDays &&
                        businessDays.map((day: string, ind: number) => {
                          return (
                            <span key={ind} style={{lineHeight: '14px'}}>
                              {day},
                            </span>
                          )
                        })}
                    </div>
                  </div>

                  <div className='d-flex align-items-center gap-3'>
                    <i className='fa fa-solid fa-clock welcome-branch-box-icon fs-3'></i>
                    <div className='d-flex gap-2 flex-wrap ps-4'>
                      <span style={{lineHeight: '14px'}}>
                        {settings?.start_time} - {settings?.end_time}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='welcome-aboutus mb-10 p-5'>
          <h1 className='mb-4 welcome-theme-text-color'>Бидний тухай:</h1>
          <span className='fs-5' style={{color: '#333'}}>
            {onlineBookingSettings.about}
          </span>
        </div>
      </div>
      <div className='welcome-footer-bg' onClick={JumpToWeb}>
        <div
          className='d-flex justify-content-between align-items-center'
          style={{
            maxWidth: 1232,
            paddingInline: 16,
            marginInline: 'auto',
            color: 'white',
            fontWeight: '700',
          }}
        >
          <span>
            Холбоос цаг захиалгын цогц системийг ЮБИСОЛ ХХК нь <br /> 2021 оноос хойш хөгжүүлж
            байна.
          </span>
          <span className='fs-6'>Xolbooc © 2021-{currentYear} ЮБИСОЛ</span>
        </div>
      </div>
    </div>
  )
}
