import {useCalendarData} from './core/CalendarDataProvider'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

function ImageSwiper() {
  const {onlineBookingSettings} = useCalendarData()
  return (
    <Carousel
      autoPlay
      infiniteLoop
      interval={3000}
      transitionTime={600} // Animation duration (1 second)
      showThumbs={false}
      showStatus={false}
      showArrows={true}
      dynamicHeight={false}
    >
      {onlineBookingSettings?.image_url?.map((image, index) => (
        <div
          key={index}
          className='welcome-img'
          style={{
            backgroundImage: `url("${image}")`,
            overflow: 'hidden',
          }}
        ></div>
      ))}
    </Carousel>
  )
}

export default ImageSwiper
