import {FC} from 'react'
import {useCalendarData} from './core/CalendarDataProvider'
import {WelcomePageTheme1} from './WelcomePageTheme1'
import {WelcomePageTheme2} from './WelcomePageTheme2'

export const WelcomePage: FC = () => {
  const {onlineBookingSettings} = useCalendarData()

  if (onlineBookingSettings?.theme_selection === 1) return <WelcomePageTheme1 />
  else if (onlineBookingSettings?.theme_selection === 2) return <WelcomePageTheme2 />
  return <>...</>
}
